import React, { useState } from 'react';
import Company from "./Company"
import Seznam from "./Seznam";
import Facebook from "./Facebook";
import Google from "./Google";
import PageLayout from './PageLayout';
import Navbar from './Navbar';
import { useReviewContext } from '../providers/ReviewProvider';

export default function Page() {
  const [{ googleStars, googleCount }] = useReviewContext()
  const [selected, setSelected] = useState("firmy");
  //console.log(googleStars, googleCount)

  return (
    <div>
      <div className='bg-black200 min-h-[100vh]'>
        <div className='bg-white border-b'>
          <div className="container bg-white w-full px-1 mx-auto">
            <Navbar />
          </div>
        </div>
        <div className="container px-1 py-2 mx-auto">
          <div className="mt-4">
            <div className="overflow-x-auto flex flex-row flex-wrap justify-between gap-1">
              <div onClick={() => setSelected("firmy")}><Company icon="./FirmyIcon.svg" selected={selected} slug="firmy" name="Firmy.cz" stars={5.0} count={12} /></div>
              <div onClick={() => setSelected("google")}><Company icon="./GoogleIcon.svg" selected={selected} slug="google" name="Google" stars={googleStars} count={googleCount}/></div>
              <div onClick={() => setSelected("facebook")}><Company icon="./FacebookIcon.svg" selected={selected} slug="facebook" name="Facebook" stars={4.5} count={3} /></div>
            </div>
          </div>

          {(selected === "firmy") && <PageLayout name="Firmy.cz" href={"https://www.firmy.cz/detail/12923680-rybareni-norsko-saf-liberec-i-stare-mesto.html#pridat-hodnoceni"} hrefName="Firmy.cz"><Seznam link="12923680-rybareni-norsko-saf-liberec-i-stare-mesto" /></PageLayout>}
          {(selected === "facebook") && <PageLayout name="Facebooku" href={""} hrefName="Facebook"><Facebook /></PageLayout>}
          {(selected === "google") && <PageLayout name="Googlu" href={""} hrefName="Google"><Google /></PageLayout>}
        </div>
      </div>
    </div>
  );
}
