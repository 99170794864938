import React from "react";

export default function Seznam(props) {
  //13162261-seznam-cz-praha-holesovice
  return (
      <div className="recenze-widget h-[70.3vh] bg-white rounded-lg"> 
        <iframe
          title="firmy-cz"
          className="h-full"
          width="100%"
          src={`https://www.firmy.cz/detail/${props.link}.html?widget&limit=200`}
        >
        </iframe>
      </div>
  );
}
