import React from "react"
import { ReactComponent as Star } from "../images/star.svg"


const Stars = ({count}) => {
	const stars = []
	for (let i = 1; i <= 5; i++) {
		if (i <= count + 0.5) {
			stars.push(<Star key={i} className="inline text-yellow600" />)
		} else {
			stars.push(<Star key={i} className="inline text-black200" />)
		}
	}
	return (
		<div>
			<div className="space-x-1 flex items-center">{stars}</div>
		</div>
	)
}

export default Stars