export default function Table() {
    const comp = [
        {
            "__typename": "CompanyType",
            "id": "38a4ccc5-ba52-4e55-98dd-682387b1d8f0",
            "slug": "ac-heating",
            "name": "AC Heating",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/acheating.jpg?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=nCc0g2lNURI4sKJwBF39H3zdy6nck%2FrVDYUMX3k7M8%2BLaqh2kH9DKEJsEDwixi5ztsFGOWjgIrpjcjwr%2BLD7tuxDVXjR8wmlXwWrQUqt7qstJaPbeKGVgK6DomZHy3RHabD85ZniecI8vUUOgz0MifDRjubPWEDX9otIj%2BTMY7mCA67D67FEW0dmlqHCzozKDUPpUEaeI0od7pLOr60wVQS8hS2%2ByyHeO9Joj33bOS%2FjiSW7521wPDkzmAFchE4PJXwODb5kYaqhYLBjd0kakcMDrSIUoWqqgbTHo%2FNMbY3LzLlxp0ZCywAyuui3IIUhoZnLMeh1Z0SUGkEgnUea8A%3D%3D",
            "verifiedProductsRating": 5,
            "verifiedProductReviewsCount": 6,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "4ecd2f37-4046-4969-8f69-a09026fce765",
            "slug": "atrea",
            "name": "ATREA",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/atrea.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=Z0KihVxcaz0tHkh4y67HOPWSJOpyRDhG6UH4%2Fa1PKuT6R1VQ%2BQQvuqP32EgO7ZcXi3zB7pvO%2BeAVq8x%2B3%2B8EQm3QLxWhiOOWQRrW8qWBwTNTlhFAlmEDMfEkOPZzOZC6LUV%2Bak%2B3OBtnSza7dTW%2BJZUVLL8MBHlC3g0EjOrekwZKc3HYRa%2FqS8Pi3wFK4dUfO6EdMEPUq5v0Iz7UVoMslHByqo%2BEDo7F6zvLtxmXVIUw1roPkqHuJqYBkoMLXaGLGvwOUmpP0WdOKKDHC3mhoGAbhyFRGCnq2UUCFrYL2%2FbyDfmH8A4auv%2FLcLGAO6DTS4OC4REpaXOxVMg2Uo65jQ%3D%3D",
            "verifiedProductsRating": 4.318181818181818,
            "verifiedProductReviewsCount": 22,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "d3f2f247-5901-4e0a-8e79-39ab18b6b9ba",
            "slug": "baxi",
            "name": "Baxi",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/baxi.jpg?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=DVS1JphXHHQBFv8xhWko7mjxvgGYsDOd2OAoS3%2F1pRBo1%2BXda7dKI%2ByEooAXAqvqWlmU8qGvEcjoAnKR1X%2BZ8cbE11afcgPlsLc4lL5sXpA%2FxOCCiavKTDdpvxFfZOc5lb2YIGfYrKZFHMK0AfA4fBKtHzE%2Fs7sujQ5bFFuXo3UFj0Z0STnve5JS3xww9iiV6SxNEqaAm8k%2BROdKv3hgOQwgbrUBiTUVi2MDX3D%2BNfhXMKnA1DAMRYIh6%2FIJZlCXMmQ43adKc6Effo44LG0McX8yHLxDEY49FiMq2SIVGsQ1eKWXkEv%2FYt%2Bce8jEIzD6tzPjOAglXtVFCNUTl4y8%2Fw%3D%3D",
            "verifiedProductsRating": 0,
            "verifiedProductReviewsCount": 0,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "40e43cd3-ac5b-4ebd-808f-5021d471e83f",
            "slug": "bosch",
            "name": "Bosch Termotechnika",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/boschLogo.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=JMKcWM3ymtWwIzvsogBNHZlPordajoZfyyQmD%2FMTa0y9UdUtenXEI1Hm9w5yRhBrSqfICRE%2BzrHV7IC5oPEeIW2D6Tijx65X7dK3TiJyaATTDBs65OgEASslapGi%2FSYNxyckkXSItAGR2GZhPIosdmpasEo06PultGN6Cgs6z6skqxoinUi7XeyJTZZIEwUub3RqO6SzX2f3mv%2BlWse%2FDGwpTdn32moO98zBhEppSoavBGlDkoZQHR02GDwUSQxC%2Fp9fZluvRib2USewdIwmax%2BZfzHgqOjyycMrnRI07e%2BBNP5qxddzAGEV4yHMLW5dMXocgQ7kgPSIqKkzks3cHw%3D%3D",
            "verifiedProductsRating": 5,
            "verifiedProductReviewsCount": 1,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "dd19f565-c2ea-480b-9475-dd1f07f1f1cf",
            "slug": "buderus",
            "name": "Buderus",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/buderus.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=oAYVdSpeQmqvb2NZ4Mr%2FEity0M8t%2FFwTV8%2FLPFog9nfzhJC7DKdtjtoUc%2BppmCj3U8iGqRb2s9KkPCmlaz%2FdHlWzHJayw%2BfAQwnWv1yCmXz1MPX4zgVi2DuJSXmako5NMeIUJYnc9Or1ziFquRTSMe9BXxY8aMrP4QOwjHDHHwT3tdsb6nJ1wlCfDuzd8jZ3ko6Wk8qc%2FmV6m4q56V8%2B2DiUCKVoX9XPEqxJ6CmKr%2BSVhJPnOvj8eg9PTexSCyofNOaBGvE967mLSTTsiHKVdF56n7ehplTEy5F8fXmA29Dpnzq9fM032tNAvvf2V9zyCaOSRyxdZrh15YTwWM1bNg%3D%3D",
            "verifiedProductsRating": 4.833333333333333,
            "verifiedProductReviewsCount": 6,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "d236965c-3b75-4c1a-b85a-e11cf0f38ae8",
            "slug": "daikin",
            "name": "Daikin",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/daikin.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=SkCNfBRRmBqrG3XcVPDy0k72mxvTKHCdIMeKqJvIs0vGlqhI%2FbFmzd3lHDXxocIq2IGSPOPgdYUoneJJWd0fWF87VVefJebkTMsyIoDx0QYOHOCsv6dP8rR%2BTYrAHHA%2FsbG6ylvTw%2B6BUAWf1LNjWwuv%2BT8msvwvkkxtj87Cz41%2FEPzBoSNmE98dYwv8MS2XXRIBvhCXAQ9k%2BEQnG2iBFLgM1tquLgFY%2F8ZVgXIeizEwStnRSM2rTxBzTjfjXbrtFS54DLOSLrVsNkNlfLq0JQ74mcyj4FxsMHkSXsdor%2BlwiX4GSNwfDebBbNkn7%2BtEfNXZtEtwkw1DwoyzcpmS2A%3D%3D",
            "verifiedProductsRating": 4.6923076923076925,
            "verifiedProductReviewsCount": 13,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "ae6f39be-7ded-492b-8e6e-20fbe213fb57",
            "slug": "de-dietrich",
            "name": "De Dietrich",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/dedietrich.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=h8OpI9ZeK15NN8yt6L3p8NEmYTyAZ1yJh1IWoJOpVtdeiICNhIuZ75Yq08hdRvuzFjplbFwHhKZlfUwJCnPKPMd1b9GH8u34kL8%2BTzmvz5qudNFcgigayyvXXGKWU4lFskiaQPYy4HYFnMZ97o5cvFu59B9ymqRxMLOcdHhhP%2BiydP9EoX3kxEo8%2FJGgHD6XjkRHXVp3V3FKu%2BKlU401viibSthLE%2BQYooSYdAxzm1e4xRCt%2F7uDl5iprkn8K9R0WgjoW9pWJ5dlj81Eu48wBopvsAtHoRKI%2BqwAGd%2Fb6y6RGy7VbIV9j1CTuWClNsB9d7NCSfH7Lk3W8R%2F4FpiSOg%3D%3D",
            "verifiedProductsRating": 4.333333333333333,
            "verifiedProductReviewsCount": 6,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "c427d120-a7dd-4dbc-a402-a57b47f7aeac",
            "slug": "ivar-cs",
            "name": "IVAR CS",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/ivar-logo.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=RePefcNJIfzIK6xkGgK351Aq2kNaekZQnMRxtuD%2Fyl1nupVGwl5zaXQB9o2WgYN%2FmOPOudTdv5pGRsOwKzo82jXz5HLJfScT%2F4LkktOI%2Bu6DgEEXudhOSF7awW%2F6Em9x4aVI6GlVrRf2lg4tUeBq7Yrumq00jwqSQqBN44eJVfDiLNqbaoHBTgiU4KmiNUMowz5oUskskJcyHG%2BOg59Ooee%2BkqD5mrZ2ZXNG3yXZPTpZ2fQPLIkNrLfqiru6q1yO7pklAwLnKHrJnvUGu1K%2Bv90NN5OOq%2FVgQcQy2bqfWkIjkf0K8DjQV9uWvtCwdcuYUZ0bcqwkfwofMXrJ4Qt2yQ%3D%3D",
            "verifiedProductsRating": 0,
            "verifiedProductReviewsCount": 0,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "d04f0f99-56a4-4874-b6ed-eb2e6ee7ada8",
            "slug": "mitsubishi-electric",
            "name": "Mitsubishi Electric",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/Mitsubishi_Electric.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=SzGrEtgeVuZtLo0Q48zkIBGYwD0H7gf2zakrUC%2FN7TSZ89QiGgO%2FIQNDWgKV9JqW4t8BMGVXdGBWskhW9faK4mTBbmQWsmZJvc8hEvCCEblK%2BBPC881TqH%2BLWB3vhd8a8olQlkoy%2FyFSbhbca%2FCuM3169jOjHAlJqDuTVgAYYslTCN%2BwSfW3O4Zr3k%2B6kEZI7W3BcLK3L6c21ju8AFFUz%2FLyHwKOMbfgUse%2FTaSsYVyiUybHNLIfvYoIWE%2Ft3PStPQp%2BrDT%2BmSNqIsb%2FgQP%2BjRlDduIlEAtvx86XXrrLRYyzkvm5s7Aq0c5iHhTm%2BW%2FDZuIUm2R7FwKw8kQQE68etw%3D%3D",
            "verifiedProductsRating": 4.538461538461538,
            "verifiedProductReviewsCount": 52,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "3d0134e7-80f6-454b-92af-a55d2bfc37ce",
            "slug": "stiebel-eltron",
            "name": "STIEBEL ELTRON",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/stiebel-eltron.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=VQkFRX%2B78Nb2k5OsYVuTjicugasJrjd164JcCPZdFOizX1kTPrGShQ7dzBnvfOh0UoZ%2BzkzN7kfUrbWf7dG%2FmhyrOK91Dl%2FZQEfRpP8YEIVTwZ0a8VSmzE%2Bhdp2r9NJjLBxmKfz1usRAeog3N2BxzraKlzYSnFdxgkN0qFzlVjta6OGCJg8W90F97xlTFSK6Q5dS%2BmfHs4wkNDW7DA9fg1CWdcf3hpAeD%2BdH2E0MnzeZIz5jK3t5nNremlxvF91cvI6Bf4QyXzvmHTQA9wk0ba0NjEpCgWxn%2F%2BmHYgxqM8920pO8%2F9fsGCoBLCVcEPttYVDjOFZDo9%2B6w%2BrXRwCvBQ%3D%3D",
            "verifiedProductsRating": 5,
            "verifiedProductReviewsCount": 8,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "4503a008-6dab-4c33-8de4-09cc03120303",
            "slug": "thermia",
            "name": "Thermia",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/thermia.jpg?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=ZQ4KI85awNWAM9hW6iLWz0jFDO8IpDMql17JidYRyjIFy3AXlV6%2BTlsXj0jwCzH8bZio1JlAPCIimSZQ2t%2FOJCR1SeGx3NtBK1FvjJbXL5F98GUzT4NxTb7rUZDNMCFRFyLrpcgORg3ZI3V1GKOukNkeCqYm0b5UJSPKKcCnuSgHpMGWmmwb9r2BFfYxW5DJQiCWUZ3rxYg3xbuZHYbA5hw1ADTLB5S3lA3RDgdhrSRJ6BcA%2B1FlIRwnI85jr1Yb4sRGmayC7uPhOR61pGAml33GynEmu78OX2F%2BgWJ%2BQHh9dTIofgPKHNe%2FK70s8YKTcu2OIbKcqLuKY1jdWOi68g%3D%3D",
            "verifiedProductsRating": 5,
            "verifiedProductReviewsCount": 1,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        },
        {
            "__typename": "CompanyType",
            "id": "a56fdf5b-e6a5-4d4a-b51d-e2490b2ec126",
            "slug": "vaillant",
            "name": "Vaillant",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/vaillant2023.jpg?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=hU3KR7A08SX1x9SDDmv3FKv%2FfkkUS1nGVYr14%2FEbpSCnCEAIMMi1BzpIO8K4Ysw9FjxBRtCPAqDfRSxspgMgPuYU2UYH9wr93aS6nIMOXxjvySDUqcIvwBeTsjPeeQE%2F6tL7Q%2FUJyVYHFcivsGyWWcl%2BqSvTcym%2BHfey5GY2V%2BQWzxongNVZZMnpPcO2Z%2FrzAHMAYWT5g5QMqZcW9%2BNsQh2wsuk4wkl9HQOf4y4zRuNT0t7TBNMHuMognmT42bLsCBIWupySisN1lkiNyCC07WLw7C2mr53t%2FnuLfDyNAfN%2BeE%2Bf9aXg9PQ8gpktZOq76%2FduVtBRnI%2FabQHT6elx2A%3D%3D",
            "verifiedProductsRating": 4.681818181818182,
            "verifiedProductReviewsCount": 22,
            "paidPartnership": true,
            "paidPartnershipType": "EDU",
        },
        {
            "__typename": "CompanyType",
            "id": "55fe495c-d6c0-43e5-9468-8f87e9e9f7a5",
            "slug": "viessmann",
            "name": "Viessmann",
            "logoUrl": "https://storage.googleapis.com/refsite-prod/logos/Viessmann.png?GoogleAccessId=refsite-service-account%40refsite-338711.iam.gserviceaccount.com&Expires=1680276871&Signature=VSWh0%2BA8OPe1J5wn7O%2FLhu2EGVYq36px%2B%2BsKhMbKRKsebPsrCMvY6LAuf82NXgpsr4EycMXz7j012excqO17W4m5zqtKw%2FOvj9p2K%2FPa39rxeO3s340aHeSHayJRHCcAtbh6GkBIViaZWKEy3ghksnZx9%2BTNAZ8Kr8mJpGzATaCrQY0sNX6Oo7ZIo9OYO%2BOsIqjaWu5W4Dn02oKlqC4qI7NJh9ay40L%2BjpMnHSMXtqCmhi6U93W7EkSm5TUTECQ8cuviTyFhpvOm8NQ24DDnOWCcZr%2FGzg7Of7oeJjE811b%2Bl3QoJGh8NAlfkEvnGWSDPNhFZtruburVuZ7QQyLNFQ%3D%3D",
            "verifiedProductsRating": 4.909090909090909,
            "verifiedProductReviewsCount": 11,
            "paidPartnership": true,
            "paidPartnershipType": "EXPERT",
        }
    ]

    return(
        <div>
            <table>
                <tr>
                    <th>Firma</th>
                    <th>Google ID</th>
                </tr>
                {comp.map((company) => (
                    <tr>
                        <td>{company.name}</td>
                    </tr>
                ))}
            </table>
        </div>
    )
}