import React from "react";
import { Route, Routes } from "react-router-dom";
import GoogleId from "./Components/GoogleId";
import Navod from "./Components/Navod";
//import "./App.css";
import Page from "./Components/Page"
import Table from "./Components/Table";

function App() {
  return (
      <Routes>
        <Route index element={<Page />} />
        <Route path="/google-id" element={<GoogleId />} />
        <Route path="/navod" element={<Navod />} />
        <Route path="/table" element={<Table />} />
      </Routes>
  )
}

export default App;
