import React from "react";
import FacebookId from "./FacebookId";
import GoogleId from "./GoogleId";

export default function Navod() {
  return (
    <div className="container px-1 py-2 mx-auto">
      <h1 className="mt-12 text-2xl lg:text-3xl text-blue700 font-bold">
        Jak přidat hodnocení třetích stran k Vaší firmě na Refsite?
      </h1>
      <p className="mt-4">
        Pro zobrazení recenzí z Googlu, Facebooku i Firem.cz budeme potřebovat
        unikátní id vaší firmy, v následujícím návodu Vám pomůžeme s jeho
        získáním.
      </p>
      <section id="google">
        <h2 className="mt-6 text-blue700 font-semibold text-xl">Google</h2>
        <div className="mt-4">
          Pro Google recenze stačí pouze vygenerovat unikátní id{" "}
          <span className="font-semibold text-blue700">
            zadáním názvu firmy (ideálně i města)
          </span>{" "}
          do následujícího formuláře a stisknout tlačítko{" "}
          <span className="font-semibold text-blue700">Načti</span>
        </div>
        <div className="mt-1">
          např.{" "}
          <span className="font-semibold text-blue700">Refsite Liberec</span>
        </div>
        <GoogleId />
      </section>
      <section id="firmy-cz">
        <h2 className="mt-12 text-blue700 font-semibold text-xl">Firmy.cz</h2>
        <div className="mt-4">
            Unikátní identifikátor budeme potřebovat také v případě recenzí z Firem.cz. Proto postupujte dle následujícího návodu:
        </div>
        <div className="mt-1">
        <ol type="1">
            <li className="mt-2"><span className="font-semibold text-blue700">1.</span> otevřete stránku <a rel="noreferrer" className="font-semibold text-blue700 hover:text-blue900" target="_blank" href="https://www.firmy.cz">www.firmy.cz</a></li>
            <li className="mt-2"><span className="font-semibold text-blue700">2.</span> vyhledejte Vaši firmu a otevřte její profil</li>
            <li className="mt-2"><span className="font-semibold text-blue700">3.</span> zkopírujte modře zvýrazněnou část URL adresy https://www.firmy.cz/detail/<span className="font-semibold text-blue700">13392775-refsite-liberec-v-kristianov</span>.html</li>
        </ol>
        </div>
      </section>
      <section id="facebook">
        <h2 className="mt-14 text-blue700 font-semibold text-xl">Facebook</h2>
        <div className="mt-4">
            Vygenerování přístupových údajů k recenzím z Facebook bude o něco složitější, je nutné se totiž proklikat přihlašovacím a autorizačním dialogem samotného facebook. Postupujte dle následujícího návodu:
        </div>
        <div className="mt-1">
        <ol type="1">
            <li className="mt-2"><span className="font-semibold text-blue700">1.</span> klikněte na tlačítko <span className="font-semibold text-blue700">LOGIN WITH FACEBOOK</span> a přihlašte se Facebook účtem, který <span className="font-semibold text-blue700">má právo na editaci Facebook stránky</span> Vaší firmy</li>
            <li className="mt-2"><span className="font-semibold text-blue700">2.</span> vyberte ze seznamu firemní Facebook stránku <span className="font-semibold text-blue700">(zaškrtněte pouze jednu)</span></li>
            <li className="mt-2"><span className="font-semibold text-blue700">3.</span> povolte tato dvě oprávnění: <span className="font-semibold text-blue700">Čtení obsahu od uživatelů na vaší stránce</span> a <span className="font-semibold text-blue700">Zobrazení seznamu stránek, které spravujete</span></li>
        </ol>
        </div>
        <FacebookId/>
      </section>
    </div>
  );
}
