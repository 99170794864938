import React, { useState, useEffect } from "react";
import FacebookLogin from "@greatsumini/react-facebook-login"; //"react-facebook-login";  
import ReviewCard from "./ReviewCard";
import Stars from "./Stars";

export default function Facebook() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [ratings, setRatings] = useState({});
  const [loaded, setLoaded] = useState(false)

  const [longTermToken, setLongTermToken] = useState(null)
  const [starsTotal, setStarsTotal] = useState(null)
  //const [accessToken, setAccessToken] = useState(null)
  useEffect(() => {
    if (login === true) {
        fetch("https://graph.facebook.com/"+ data.userID + "/accounts?fields=name,access_token,id&access_token=" + data.accessToken)
        .then((response) => response.json())
        .then((data) => {console.log(data.data[0])
          setPageInfo(data.data[0])
        });
    }
    // eslint-disable-next-line 
  }, [data])

  const appId = "772700694049700"
  const appSecret = "50de34bf4869f41f2e5d2a8b9a4ca96c"

  useEffect(() => {
    if (login === true) {
      console.log("loading long term token")
      fetch(`https://graph.facebook.com/oauth/access_token?  
      grant_type=fb_exchange_token&          
      client_id=${appId}&
      client_secret=${appSecret}&
      fb_exchange_token=${pageInfo.access_token}`)
      .then((response) => response.json())
      .then((data) => {console.log(data)
        setLongTermToken(data.access_token)
      });
    }
    // eslint-disable-next-line 
  }, [pageInfo])

  useEffect(() => {
    if (login === true && longTermToken !== null) {
      console.log("loading ratings")
      fetch(`https://graph.facebook.com/${pageInfo.id}/ratings?fields=reviewer,has_rating,has_review,rating,created_time,recommendation_type,review_text&access_token=${longTermToken}&limit=100}`)
      .then((response) => response.json())
      .then((data) => {console.log(data)
        setRatings(data.data)
        const positive = data.data.filter((r) => r.recommendation_type === "positive").length
        const negative = data.data.filter((r) => r.recommendation_type === "negative").length
        setStarsTotal((positive * 5 + negative)/(positive + negative))
      setLoaded(true)});
    }
    // eslint-disable-next-line 
  }, [longTermToken])



  // const responseFacebook = (response) => {
  //   console.log(response);
  //   // Login failed
  //   if (response.status === "unknown") {
  //     alert("Login failed!");
  //     setLogin(false);
  //     return false;
  //   }
  //   setData(response);

  //   //setPicture(response.picture.data.url);
  //   if (response.accessToken) {
  //     setLogin(true);
  //   } else {
  //     setLogin(false);
  //   }
  // };
  const logout = () => {
    setLogin(false);
    setData({});
    //setPicture("");
  };


  console.log(ratings)

  return (
    <div className="container">
      {!login && (
        <FacebookLogin
          appId="772700694049700"
          autoLoad={false}
          initParams={{
            version: 'v13.0',
            xfbml: true,
          }}
          onSuccess={(res) => {
            console.log(res);
            setData(res);
            setLogin(true);
          }}
          // onFailure={(res) => {
          //   console.log(res);
          // }}
          // onProfileSuccess={(response) => {
          //   console.log('Get Profile Success!', response);
          // }}
          //fields="name,email,picture" //pages_manage_posts,pages_manage_metadata,
          scope="pages_read_user_content,pages_read_engagement,pages_show_list"
          //callback={responseFacebook}
          //icon="fa-facebook"
        />
      )}

      {login && (
        // <div className="card">
        //   <div className="card-body">
        //     <img className="rounded" src={picture} alt="Profile" />
        //     <h5 className="card-title">{data.name}</h5>
        //     <p className="card-text">Email ID: {data.email}</p>
        <div className="flex justify-between">
            <div className="cursor-pointer w-fit px-2 py-1 text-white rounded bg-blue700" onClick={logout}>
              Odhlásit se
            </div>

            {starsTotal !== null && <div className="flex"><div className="mr-1 text-blue700 font-semibold">{starsTotal}</div><div className="mt-1"><Stars count={starsTotal}/></div></div>}
       </div>
        // </div>
      )}
      {loaded && <>
      {/* <h3>{pageInfo.name}</h3> */}
             <ul>
                 {ratings.map((r, index) =>
                 <ReviewCard key={index} review={r.review_text} time={new Date(r.created_time).toLocaleDateString()} recommendation={r.recommendation_type} name={r.reviewer ? r.reviewer.name : "anonymní uživatel"}></ReviewCard>
                //  <li key={index}>
                //      <div>{r.created_time}</div>
                //      <div>{r.recommendation_type}</div>
                //      <div>{r.review_text}</div>
                //  </li>
                 )}
             </ul>
             </>
      }
    </div>
  );
}