import React, { useState, useEffect } from "react";
import Stars from "./Stars";

export default function Company(props) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (props.selected === props.slug) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [props.selected, props.slug]);

  return (
    <div
      className={`w-full bg-white borde cursor-pointer ${
        isSelected ? "border-blue700" : "border-black300 hover:border-blue700"
      }  min-w-min border rounded-lg mb-2`}
    >
      <div className="flex rounded-md mt-2 pt-1 font-bold pb-3 px-3">
        {props.icon && (
          <div className="w-20 flex justify-center">
            <img
              alt={props.name}
              className="h-12 px-1 py-2"
              src={props.icon}
            ></img>
          </div>
        )}
        <div className="pl-4 ">
          <div className="flex my-1 w-full">
            <span className="text-lg text-blue700 font-bold">{props.stars}</span>
            <span className="text-black600 mx-2 mt-1 text-sm font-light">
              ({props.stars*20}%)
            </span>
            <div className="mt-1">
              <Stars count={props.stars} />
            </div>
          </div>
          <div className="text-black600 text-xs font-light">{props.count} hodnocení</div>
        </div>
      </div>
      {/* <div className="text-sm w-32 text-center">
        <span className="align-bottom">{i.date}</span>
      </div> */}
    </div>
  );
}
