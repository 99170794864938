import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
export default function FacebookId() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [loaded, setLoaded] = useState(false)
  //const [accessToken, setAccessToken] = useState(null)
  useEffect(() => {
    if (login === true) {
        fetch("https://graph.facebook.com/"+ data.id + "/accounts?fields=name,access_token,id&access_token=" + data.accessToken)
        .then((response) => response.json())
        .then((data) => {console.log(data.data[0])
          setPageInfo(data.data[0])
          setLoaded(true)
        });
    }
    // eslint-disable-next-line 
  }, [data])

  const responseFacebook = (response) => {
    console.log(response);
    // Login failed
    if (response.status === "unknown") {
      alert("Login failed!");
      setLogin(false);
      return false;
    }
    setData(response);

    //setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };
  const logout = () => {
    setLogin(false);
    setData({});
    setPageInfo(null)
    setLoaded(false)
  };


  return (
    <div className="container px-1 py-2 mx-auto mb-12">
    <div className="mx-6 mt-3 w-fit bg-blue50 shadow-md p-3 rounded-md max-w-full">
      {!login && (
        <FacebookLogin
          appId="772700694049700"
          autoLoad={false}
          fields="name,email,picture"
          scope="pages_manage_posts,pages_read_user_content,pages_manage_metadata,pages_read_engagement,pages_show_list"
          callback={responseFacebook}
          icon="fa-facebook"
        />
      )}

      {login && (
        // <div className="card">
        //   <div className="card-body">
        //     <img className="rounded" src={picture} alt="Profile" />
        //     <h5 className="card-title">{data.name}</h5>
        //     <p className="card-text">Email ID: {data.email}</p>
            <div className="cursor-pointer px-2 py-1 w-fit text-white rounded-lg bg-blue700" onClick={logout}>
              Odhlásit se
            </div>
        //   </div>
        // </div>
      )}
      {loaded && <><p className="text-sm overflow-auto mt-4">Id stránky: {pageInfo.id}</p>
      <p className="text-xs overflow-auto mt-2">Access token: {pageInfo.access_token}</p></>
      }
    </div>
    </div>
  );
}