import React, { useState } from "react";
import axios from "axios";

export default function GoogleId() {
  const [nazev, setNazev] = useState("");
  const [id, setId] = useState("");

  const url = "https://refsite-calculator.herokuapp.com/reviews/google-id";

  async function getData() {
    setId("");
    axios.post(url, { nazev: nazev }).then((res) => {
      setId(res.data);
    }).catch((err) => setId("Chyba: "+ err.message));
  }

  return (
    <div className="container px-1 py-2 mx-auto">
      <div className="mx-6 mt-3 w-fit bg-blue50 shadow-md p-3 rounded-md">
        <input
          placeholder="Název firmy"
          className="w-96 bg-white px-2 py-1 rounded-lg border border-blue700"
          onChange={(e) => setNazev(e.target.value)}
        ></input>
        <button
          className="bg-blue700 text-white hover:bg-blue800 ml-2 px-2 py-1 rounded-lg border border-blue700"
          onClick={getData}
        >
          Načti
        </button>
        {id &&
        <p className="pt-2 font-semibold">Id: {id}</p>}
      </div>
    </div>
  );
}
