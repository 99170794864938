import React, { useState } from "react";
import axios from "axios";
import ReviewCard from "./ReviewCard";
import { Link } from "react-router-dom";
import { useReviewContext, SET_GOOGLE } from "../providers/ReviewProvider";

export default function Google(props) {
  const [id, setId] = useState("ChIJS69V6pBP5m8RJdIuVC5EAFw");
  const [dispatch] = useReviewContext();
  //const [starsCount, setStarsCount] = useState(null);
  //const [ratingsTotal, setRatingsTotal] = useState(0);
  const [reviews, setReviews] = useState([]);

  const url = "https://refsite-calculator.herokuapp.com/reviews/google";

  async function getData() {
    axios.post(url, { id: id }).then((res) => {
      console.log(res.data.result);
      //setStarsCount(res.data.result.rating);
      //setRatingsTotal(res.data.result.user_ratings_total);
      setReviews(res.data.result.reviews);
      dispatch({type: SET_GOOGLE, payload: {stars: res.data.result.rating, count: res.data.result.user_ratings_total}})
    });
  }

  useState(() => {
    getData();
  }, [])

  return (
    <div>
      <input
        placeholder="id"
        className="w-96 rounded-lg px-2 py-1 border border-blue700"
        value={id}
        onChange={(e) => setId(e.target.value)}
      ></input>
      <button className="bg-blue700 text-white hover:bg-blue800 ml-2 px-2 py-1 rounded-lg border border-blue700" onClick={getData}>Načti</button>
      <Link className="bg-blue700 text-white hover:bg-blue800 ml-2 px-2 py-[0.43rem] rounded-lg border border-blue700" to="./google-id" target="_blank">Získání id</Link>

      <div>
        {reviews &&
          reviews.map((r, index) => (
            <ReviewCard
              key={index}
              time={r.relative_time_description}
              name={r.author_name}
              stars={r.rating}
              review={r.text}
            />
          ))}
      </div>
    </div>
  );
}
