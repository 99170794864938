import React from "react";
import { ReactComponent as Avatar } from "../images/avatar.svg";
import Stars from "./Stars";

export default function ReviewCard(props) {
  return (
    <div className="p-3 mt-3">
      <div className="grid grid-cols-2 gap-4 place-content-between">
        <div className="flex flex-row items-center mb-4">
          {props.imageUrl ? (
            <img alt="avatar" className="w-12 h-12 min-w-12" src={props.imageUrl} />
          ) : (
            <Avatar className="w-12 h-12 min-w-12" />
          )}
          <div className="ml-4">
            <div className="text-blue700 text-lg font-semibold">
              {props.name}
            </div>
            <div className="flex flex-row">
              {props.stars && <Stars count={props.stars}/>}
              {props.recommendation && props.recommendation === "positive" ? <div className="text-xs text-green-600">doporučuje</div> : <div className="text-xs text-red-600">nedoporučuje</div>}
              <div className="ml-4 text-xs text-gray-600">{props.time}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm">{props.review}</div>
    </div>
  );
}
