import React from "react";

export default function PageLayout(props) {
    return (
        <div className="mt-4">
            <div className="flex justify-between">
                <div className="font-semibold">Recenze z {props.name}</div>
                <a className="font-semibold text-blue700 hover:text-blue900" href={props.href} rel="noreferrer" target="_blank">Přidat recenzi na {props.hrefName}</a>
            </div>
            <div className="bg-white mt-2 p-3 mb-2 border border-black300 rounded-lg">
                {props.children}
            </div>
        </div>
    )
}