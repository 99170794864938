import React from "react";

export default function Navbar() {
  return (
    <div className="flex items-center justify-center sm:justify-start">
      <div
        className="cursor-pointer border-b-2 border-white hover:border-blue100 py-2 px-2.5 sm:py-4 sm:px-10 text-black900 font-bold text-14 sm:text-16 flex flex-row space-x-4 hover:bg-blue100"
      >
        <div>Přehled</div>
      </div>
      <div
        className="cursor-pointer border-b-2 border-white hover:border-blue100 py-2 px-2.5 sm:py-4 sm:px-10 text-black900 font-bold text-14 sm:text-16 flex flex-row space-x-4 hover:bg-blue100"
      >
        <div>
          Hodnocení produktů{" "}
          <span className="text-black-700 font-normal">(23)</span>
        </div>
      </div>
      <div
        className="cursor-pointer border-b-2 border-blue700 py-2 px-2.5 sm:py-4 sm:px-10 text-black900 font-bold text-14 sm:text-16 flex flex-row space-x-4 bg-black100 pointer-events-none"
      >
        <div>Neověřené recenze</div>
      </div>
      <div
        className="cursor-pointer border-b-2 border-white hover:border-blue100 py-2 px-2.5 sm:py-4 sm:px-10 text-black900 font-bold text-14 sm:text-16 flex flex-row space-x-4 hover:bg-blue100"
      >
        <div>Edukativní obsah</div>
      </div>
    </div>
  );
}
