import React, { createContext, useReducer, useContext } from "react"
export const RESET = "RESET"
export const SET_GOOGLE = "SET_GOOGLE"

const initialState = {
	googleCount: 3,
	googleStars: 4,
}

const dataReducer = (state, action) => {
	switch (action.type) {
		case SET_GOOGLE: {
			return {
				...state,
				googleStars: action.payload.stars,
				googleCount: action.payload.count,
			}
		}

		case RESET: {
			return initialState
		}
		default: {
			return state
		}
	}
}

//const storedData = JSON.parse(localStorage.getItem(STORAGE_KEY));

export const Context = createContext()
export const ReviewProvider = ({ children, ...rest }) => {
	const [store, dispatch] = useReducer(dataReducer, initialState) // storedData || initialState

	return <Context.Provider value={[store, dispatch]}>{children}</Context.Provider>
}

export const useReviewContext = () => useContext(Context)
